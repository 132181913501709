<template>
    <div style="text-align: left;background: #FFFFFF;">
        <el-card>
            <div @click="$oucy.back()" class="m-b-20 pointer">&lt;返回</div>
            <!-- <h2 class="zdtitle">账单明细</h2> -->
            <el-button-group>
                <el-button :type="distributionLevel==0?'primary':''" @click="setType(0)" size="mini">一级</el-button>
                <el-button :type="distributionLevel==1?'primary':''" @click="setType(1)" size="mini">二级</el-button>
                <el-button :type="distributionLevel==2?'primary':''" @click="setType(2)" size="mini">三级</el-button>
            </el-button-group>
            <el-table :data="prlductData.content" style="width: 100%" class="m-t-10" v-if="prlductData.content">
                <el-table-column prop="date" label="分销用户" >
                    <div slot-scope="scope" v-if="scope.row" class="df-ai-c">
                        <img :src="$oucy.ossUrl+scope.row.detailAvatar" class="head" alt="">
                        <div class="c-2 m-l-5">{{scope.row.detailNickname}}</div>
                    </div>
            
                </el-table-column>
                <el-table-column prop="userPhoneNumber" label="联系方式"></el-table-column>

                <el-table-column prop="subordinatesNum" v-if="distributionLevel!=2" label="下级人数"></el-table-column>
                <el-table-column prop="superiorUserName" label="推荐人" v-if="distributionLevel!=0"></el-table-column>
       
                <el-table-column prop="userParentCreatetime" label="加入时间" width="160px"></el-table-column>
            </el-table>
            <el-pagination class="zdfy" v-if="prlductData" background layout="sizes, prev, pager, next, jumper, ->, total, slot" :page-size="prlductData.size" :total="prlductData.totalElements" @size-change="sizeChange" @current-change="currentChange" @prev-click="currentChange" @next-click="currentChange">
            </el-pagination>
        </el-card>
    </div>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";

import { localSet, localGet, localDel } from "@/store/store"
import { distribution } from "@/service"
export default {
    name: "EditUser",
    components: {},
    data() {
        return {
            distributionLevel: 0,
            prlductData: {},
            limit: 10,
            start: 0,
        }
    },
    mounted() {
        this.queryChild()
    },
    methods: {
        setType(v) {
            if (this.distributionLevel == v) return
            this.distributionLevel = v
            this.prlductData = {}
            this.limit = 10
            this.start = 0
            this.queryChild(v)

        },

        sizeChange: function(limit) {
            const me = this;
            me.limit = limit;
            // me.queryChild();
            this.queryChild()
        },
        currentChange: function(current) {
            const me = this;
            me.start = current - 1;
            // me.queryChild();
            this.queryChild()
        },

        // 明细
        queryChild() {
            distribution.queryChild({
                limit: this.limit,
                start: this.start,
                distributionLevel: this.distributionLevel
            }).then(res => {
                this.prlductData = res
            })
        },

    }
}
</script>
<style scoped lang="less">
.payimg {
    display: flex;
    align-content: center;
}

.zdtitle {
    padding-bottom: 15px;
}

.zdfy {
    padding-top: 20px;
}

.head {
    width: 32px;
    height: 32px;
    border-radius: 32px;
}
.c-67973D{
    color: #67973D;
}.c-2090FF{
    color: #2090FF;
}
</style>